import React from 'react';
import './styles.scss';

const FollowUs = () => {
    return (
        <div className='sSocialFollowUsWrap'>
            <div className='sSocialContainer'>
                <div className='sFollowUsHeader'>
                    <h3>
                        Every time you do business with Springworks, a part of our profits proceeds to our community
                        initiatives.
                    </h3>
                    <a href='https://ctt.ac/7E1ne' className='sShareInvite' target='_blank' rel='noreferrer noopener'>
                        Share on Twitter
                    </a>
                </div>
                <div className='sFollow'>
                    <p>Follow us</p>
                    <a target='_blank' rel='noreferrer noopener' href='https://www.instagram.com/lifeatspringworks/'>
                        <img
                            src={'https://sw-assets.gumlet.io/common/insta-outline.svg'}
                            data-src={'https://sw-assets.gumlet.io/common/insta-outline.svg'}
                            alt='Springworks Insta'
                        />
                    </a>
                    <a target='_blank' rel='noreferrer noopener' href='https://twitter.com/springroleinc/'>
                        <img
                            src={'https://sw-assets.gumlet.io/common/twitter-outline.svg'}
                            data-src={'https://sw-assets.gumlet.io/common/twitter-outline.svg'}
                            alt='Springworks Twitter'
                        />
                    </a>
                    <a target='_blank' rel='noreferrer noopener' href='https://www.facebook.com/springrole/'>
                        <img
                            src={'https://sw-assets.gumlet.io/common/fb-outline.svg'}
                            data-src={'https://sw-assets.gumlet.io/common/fb-outline.svg'}
                            alt='Springworks Facebook'
                        />
                    </a>
                    <a target='_blank' rel='noreferrer noopener' href='https://www.linkedin.com/company/springrole/'>
                        <img
                            src={'https://sw-assets.gumlet.io/common/linkedin-outline.svg'}
                            data-src={'https://sw-assets.gumlet.io/common/linkedin-outline.svg'}
                            alt='Springworks Linkedin'
                        />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default FollowUs;
