import React from 'react';
import './styles.scss';

const Story = () => {
    return (
        <div className='sStoryWrap'>
            <div className='sSocialContainer'>
                <div className='sStoryTitle'>
                    <h2>The Story</h2>
                </div>
            </div>
            <div className='sStoryInnerWrap'>
                <div className='sSocialContainer'>
                    <div className='sStoryTitle'>
                        <h2>Behind our Initiative</h2>
                    </div>

                    <div className='sStoryWrap'>
                        <div className='sStoryTxt'>
                            <span>01</span>

                            <div className='sStoryContent'>
                                <h4>All our initiatives came to a halt when COVID hit us</h4>
                                <div className='sStoryImg'>
                                    <img
                                        src={
                                            'https://sw-assets.gumlet.io/giving-back/springworks-initiatives-during-covid.jpg?blur=30'
                                        }
                                        data-src={
                                            'https://sw-assets.gumlet.io/giving-back/springworks-initiatives-during-covid.jpg'
                                        }
                                        alt='Springworks Initiatives during COVID'
                                    />
                                </div>
                                <p>
                                    After COVID hit us, we went remote. The kitchen we set up for employee lunches and
                                    snacks at Springworks with groceries, utensils and all our kitchenware was no longer
                                    functional. We wanted to begin utilising the kitchen and support people around the
                                    neighbourhood.
                                </p>
                            </div>
                        </div>

                        <div className='sStoryImg'>
                            <img
                                src={
                                    'https://sw-assets.gumlet.io/giving-back/springworks-initiatives-during-covid.jpg?blur=30'
                                }
                                data-src={
                                    'https://sw-assets.gumlet.io/giving-back/springworks-initiatives-during-covid.jpg'
                                }
                                alt='Springworks Initiatives during COVID'
                            />
                        </div>
                    </div>

                    <div className='sStoryWrap'>
                        <div className='sStoryImg'>
                            <img
                                src={
                                    'https://sw-assets.gumlet.io/giving-back/springworks-kitchen-during-lockdown.jpg?blur=30'
                                }
                                data-src={
                                    'https://sw-assets.gumlet.io/giving-back/springworks-kitchen-during-lockdown.jpg'
                                }
                                alt='Springworks Kitchen during Lockdown'
                            />
                        </div>

                        <div className='sStoryTxt'>
                            <span>02</span>
                            <div className='sStoryContent'>
                                <h4>We started setting up a community kitchen amidst the lockdown</h4>
                                <div className='sStoryImg'>
                                    <img
                                        src={
                                            'https://sw-assets.gumlet.io/giving-back/springworks-kitchen-during-lockdown.jpg?blur=30'
                                        }
                                        data-src={
                                            'https://sw-assets.gumlet.io/giving-back/springworks-kitchen-during-lockdown.jpg'
                                        }
                                        alt='Springworks Kitchen during Lockdown'
                                    />
                                </div>
                                <p>
                                    We started talking to the authorities for permission to set up the logistics for a
                                    community kitchen amidst the lockdown. As the government offices were not working at
                                    it full capacity getting the paperwork done was one of the first challenges we had
                                    to tackle.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='sStoryWrap'>
                        <div className='sStoryTxt'>
                            <span>03</span>
                            <div className='sStoryContent'>
                                <h4>With the support of our friends, we started distributing meals</h4>
                                <div className='sStoryImg'>
                                    <img
                                        src={'https://sw-assets.gumlet.io/giving-back/meals-distribution.jpg?blur=30'}
                                        data-src={'https://sw-assets.gumlet.io/giving-back/meals-distribution.jpg'}
                                        alt='Meals Distribution'
                                    />
                                </div>
                                <p>
                                    In a couple of weeks, everyone from Springworks jumped in to support our initiative.
                                    Our friends started donating rice, pulses, vegetables, condiments and other
                                    groceries.
                                </p>
                            </div>
                        </div>
                        <div className='sStoryImg'>
                            <img
                                src={'https://sw-assets.gumlet.io/giving-back/meals-distribution.jpg?blur=30'}
                                data-src={'https://sw-assets.gumlet.io/giving-back/meals-distribution.jpg'}
                                alt='Meals Distribution'
                            />
                        </div>
                    </div>

                    <div className='sStoryWrap'>
                        <div className='sStoryImg'>
                            <img
                                src={'https://sw-assets.gumlet.io/giving-back/serving-food-the-community.jpg?blur=30'}
                                data-src={'https://sw-assets.gumlet.io/giving-back/serving-food-the-community.jpg'}
                                alt='Serving Food the Community'
                            />
                        </div>

                        <div className='sStoryTxt'>
                            <span>04</span>
                            <div className='sStoryContent'>
                                <h4>Giving back to the community</h4>
                                <div className='sStoryImg'>
                                    <img
                                        src={
                                            'https://sw-assets.gumlet.io/giving-back/serving-food-the-community.jpg?blur=30'
                                        }
                                        data-src={
                                            'https://sw-assets.gumlet.io/giving-back/serving-food-the-community.jpg'
                                        }
                                        alt='Serving Food the Community'
                                    />
                                </div>
                                <p>
                                    Today, we’ve serving 9 different dishes to more than a 100 people every day. We hope
                                    to end 2020 on a good note for these people. We want to help everyone in need and
                                    carry on this nobel challenge every day from now on.{' '}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Story;
