import React from 'react';
import './styles.scss';

const People = () => {
    return (
        <div className='sPeopleWrap'>
            <div className='sSocialContainer'>
                <div className='sPeopleDate'>
                    <p> date 2017-18 </p>
                </div>
                <div className='sPeopleHeader'>
                    <h1>These people need your help</h1>
                    <p>info. gathered by Robinhood Army</p>
                </div>

                <div className='sPeopleValueWrap'>
                    <div className='sPeopleValue'>
                        <span>India</span>
                        <h5>190 Million</h5>
                        <p>People are malnourished</p>
                    </div>
                    <div className='sPeopleValue'>
                        <span>age 15-49</span>
                        <h5>51.4%</h5>
                        <p>Women are anaemic</p>
                    </div>
                    <div className='sPeopleValue'>
                        <span>under 5yr</span>
                        <h5>34.7%</h5>
                        <p>Children are stunted</p>
                    </div>
                </div>

                <div className='sPeoplePara'>
                    <p>
                        When we were working out of our office in Koramangala, Bengaluru we used to spend a lot of time
                        with our work-staff - things like setting up events, celebrating festivals and hosting special
                        luncheons. We helped them educate their children, distributed laptops and set them up with Khan
                        Academy.{' '}
                    </p>
                    <p>And then...</p>
                </div>
            </div>
        </div>
    );
};

export default People;
