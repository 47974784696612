import React from 'react';
import './styles.scss';

const Header = (props) => {
    return (
        <div className='sHeaderWrap'>
            <div className='sHeaderBG'>
                <div className='sSocialContainer'>
                    <div className='sHeaderContentMob'>
                        <span>We’ve</span>
                        <span>always</span>
                        <span>believed that</span>
                        <span>empathy</span>
                        <span>starts from</span>
                        <span>home</span>
                        <span className='mobSmallTxt'>Now, we’re taking a </span>
                        <span className='mobSmallTxt'>step forward.</span>
                    </div>
                    <h1 className='sHeaderContent'>
                        <span>We’ve always believed that</span>
                        <span className='lastHeader'>empathy starts from home</span>
                        <span className='tinylastHeader'>Now, we’re taking a step forward.</span>
                    </h1>
                </div>
            </div>
        </div>
    );
};

export default Header;
