import React from 'react';
import Navbar from '../../springworks/Navbar';
import './styles.scss';

const NavBar = () => {
    return (
        <div className='sNavbar'>
            <div className='sSocialContainer'>
                <Navbar />
            </div>
        </div>
    );
};

export default NavBar;
