import React from 'react';
import './styles.scss';

const TwitterPost = () => {
    return (
        <div className='sSocialTwitterPostWrap'>
            <div className='sSocialContainer'>
                <a
                    href='https://twitter.com/kar2905/status/1311876836381450243?s=20'
                    target='_blank'
                    rel='noreferrer noopener'
                >
                    <div className='sSocialTwitterPost'>
                        <div className='twitterPostHeader'>
                            <div className='twitterHeaderProfile'>
                                <div className='sProfileImg'>
                                    <img
                                        src={'https://sw-assets.gumlet.io/giving-back/kartik-twitter.jpg?blur=30'}
                                        data-src={'https://sw-assets.gumlet.io/giving-back/kartik-twitter.jpg'}
                                        alt='Springworks Twitter'
                                    />
                                </div>
                                <div className='sProfileTxt'>
                                    <h5>Kartik Mandaville</h5>
                                    <p>@kar2905</p>
                                </div>
                            </div>
                            <div className='twitterViewBtn'>
                                <a
                                    href='https://twitter.com/kar2905/status/1311876836381450243?s=20'
                                    target='_blank'
                                    rel='noreferrer noopener'
                                >
                                    <img
                                        src={'https://sw-assets.gumlet.io/common/twitter-blue.svg'}
                                        data-src={'https://sw-assets.gumlet.io/common/twitter-blue.svg'}
                                        alt='springworks-twitter'
                                        placeholder={'tracedSVG'}
                                    />
                                    view
                                </a>
                            </div>
                        </div>

                        <div className='twitterContent'>
                            <div className='twitterContentPara'>
                                <p>
                                    1/n Startups are all about the journey and not the destiny. Do everything with love.
                                    Thread on our new initiative. We setup our kitchen last year{' '}
                                    <a
                                        href='https://twitter.com/springroleinc'
                                        target={'_blank'}
                                        rel={'noreferrer noopener'}
                                    >
                                        {' '}
                                        @springroleinc.
                                    </a>{' '}
                                    Started with snacks, then expanded to lunch + breakfast doing 250+ meals per day.{' '}
                                </p>
                            </div>
                            <div className='twitterContentImg'>
                                <img
                                    src={
                                        'https://sw-assets.gumlet.io/giving-back/springworks-spreading-love-with-the-people.jpg?blur=30'
                                    }
                                    data-src={
                                        'https://sw-assets.gumlet.io/giving-back/springworks-spreading-love-with-the-people.jpg'
                                    }
                                    alt='Springworks spreading love with the people'
                                />
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    );
};

export default TwitterPost;
