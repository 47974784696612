import React from 'react';
import './styles.scss';

const OurImpact = () => {
    return (
        <div className='sSocialContainer'>
            <div className='sImpactWrap'>
                <div className='sImpactWrapInner'>
                    <div className='sImpactLeft'>
                        <div className='sImpactLeftHeader'>
                            <h5>Our</h5>
                            <h3>
                                Imp
                                <br />
                                act
                            </h3>
                            <h2>Impact</h2>
                            <p>
                                We've distributed over 1,00,000 meals in Bengaluru. There's a long way to go and we're
                                gearing up to bring more smiles to these beautiful people. Today, we are distributing
                                food to over 750 people daily and are trying to get to the 1,000 mark.
                            </p>
                            <p>
                                If you would like to support our initiative, get in touch with{' '}
                                <a href='https://twitter.com/kar2905' target='_blank' rel='noopener noreferrer'>
                                    Kartik Mandaville, CEO of Springworks
                                </a>
                                .
                            </p>
                        </div>
                        <img
                            src={'https://sw-assets.gumlet.io/giving-back/giving-back-to-the-community.jpg?blur=30'}
                            data-src={'https://sw-assets.gumlet.io/giving-back/giving-back-to-the-community.jpg'}
                            alt='Giving back to the community'
                        />
                    </div>
                    <div className='sImpactRight'>
                        <img
                            src={'https://sw-assets.gumlet.io/giving-back/happy-people.jpg?blur=30'}
                            data-src={'https://sw-assets.gumlet.io/giving-back/happy-people.jpg'}
                            alt='Happy People'
                        />
                        <img
                            src={'https://sw-assets.gumlet.io/giving-back/how-springworks-journey-started.jpg?blur=30'}
                            data-src={'https://sw-assets.gumlet.io/giving-back/how-springworks-journey-started.jpg'}
                            alt='How Springworks Journey Started'
                        />
                    </div>
                </div>
                <div className='sImpactLGImg'>
                    <img
                        src={'https://sw-assets.gumlet.io/giving-back/food-distribution-by-springworks.jpg?blur=30'}
                        data-src={'https://sw-assets.gumlet.io/giving-back/food-distribution-by-springworks.jpg'}
                        alt='Food Distribution by Springworks'
                    />
                </div>
            </div>

            <div className='sImpactUpComingWrap'>
                <div className='sImpactUpComing'>
                    <div className='upComing'>
                        <h4>
                            Upcoming
                            <br />
                            drives
                        </h4>
                        <a
                            href='https://ctt.ac/7E1ne'
                            className='sShareInvite'
                            target='_blank'
                            rel='noreferrer noopener'
                        >
                            Share on Twitter
                        </a>
                    </div>
                    <div className='sImpactEveryDay'>
                        <div className='everyDayLeft'>
                            <h2>
                                Every
                                <br />
                                day
                            </h2>
                        </div>
                        <div className='everyDayRight'>
                            <span>10:00 - 14:00</span>
                            <h3>Food distribution</h3>
                            <p>
                                Near Ayyappa Bakery, 22nd Cross Rd, Lakshman Rao Nagar, Dr. Ambedkar Nagar, Adugodi,
                                Bengaluru, Karnataka - 560047
                            </p>
                        </div>
                    </div>
                    <div className='mobsShareInvite'>
                        <a
                            href='https://ctt.ac/7E1ne'
                            className='sShareInvite'
                            target='_blank'
                            rel='noreferrer noopener'
                        >
                            Make a donation
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurImpact;
