import React from 'react';
import Navbar from '../../components/giving-back/Navbar';
import { Helmet } from 'react-helmet';
import FollowUs from '../../components/giving-back/FollowUs';
import Header from '../../components/giving-back/Header';
import OurImpact from '../../components/giving-back/OurImpact';
import People from '../../components/giving-back/People';
import Story from '../../components/giving-back/Story';
import TwitterPost from '../../components/giving-back/TwitterPost';
import Footer from '../../components/springworks/Footer';
import './styles.scss';

const GivingBack = () => {
    return (
        <>
            <Helmet>
                <title>Giving Back | Community Initiatives | Springworks</title>
                <meta
                    data-react-helmet='true'
                    name='description'
                    content='Springworks always believed that empathy starts at home. That’s why we’re giving back to the community. Here’s the story behind our initiative of distributing food to over 9,000 people.'
                />
                <meta property='og:site_name' content='Springworks' />
                <meta property='og:title' content='Giving Back | Community Initiatives | Springworks' />
                <meta
                    property='og:description'
                    content='Springworks always believed that empathy starts at home. That’s why we’re giving back to the community. Here’s the story behind our initiative of distributing food to over 9,000 people.'
                />
                <meta property='og:url' content='https://www.springworks.in/giving-back/' />
                <meta property='og:type' content='website' />
                <meta property='og:image' content='https://sw-assets.gumlet.io/giving-back/GivingBack.jpeg' />
                <meta
                    property='og:image:secure_url'
                    content='https://sw-assets.gumlet.io/giving-back/GivingBack.jpeg'
                />
                <meta property='og:image:width' content='1280' />
                <meta property='og:image:height' content='640' />
                <meta property='twitter:title' content='Giving Back | Community Initiatives | Springworks' />
                <meta
                    property='twitter:description'
                    content='Springworks always believed that empathy starts at home. That’s why we’re giving back to the community. Here’s the story behind our initiative of distributing food to over 9,000 people.'
                />
                <meta property='twitter:card' content='website' />
                <meta property='twitter:card' content='summary_large_image' />
                <meta property='twitter:image' content='https://sw-assets.gumlet.io/giving-back/GivingBack.jpeg' />
                <meta property='twitter:site' content='@springroleinc' />
                <link rel='canonical' href='https://www.springworks.in/giving-back/' />
            </Helmet>
            <div className='sSocialWrap'>
                <Navbar />
                <Header />
                <People />
                <Story />
                <OurImpact />
                <TwitterPost />
                <FollowUs />
                <Footer />
            </div>
        </>
    );
};

export default GivingBack;
